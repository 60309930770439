// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-berita-detail-js": () => import("./../../../src/pages/berita-detail.js" /* webpackChunkName: "component---src-pages-berita-detail-js" */),
  "component---src-pages-berita-js": () => import("./../../../src/pages/berita.js" /* webpackChunkName: "component---src-pages-berita-js" */),
  "component---src-pages-daerah-js": () => import("./../../../src/pages/daerah.js" /* webpackChunkName: "component---src-pages-daerah-js" */),
  "component---src-pages-daerah-wilayah-js": () => import("./../../../src/pages/daerah-wilayah.js" /* webpackChunkName: "component---src-pages-daerah-wilayah-js" */),
  "component---src-pages-daftar-umkm-js": () => import("./../../../src/pages/daftar-umkm.js" /* webpackChunkName: "component---src-pages-daftar-umkm-js" */),
  "component---src-pages-detail-bandara-js": () => import("./../../../src/pages/detail-bandara.js" /* webpackChunkName: "component---src-pages-detail-bandara-js" */),
  "component---src-pages-detail-umkm-js": () => import("./../../../src/pages/detail-umkm.js" /* webpackChunkName: "component---src-pages-detail-umkm-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-ikn-js": () => import("./../../../src/pages/ikn.js" /* webpackChunkName: "component---src-pages-ikn-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastruktur-js": () => import("./../../../src/pages/infrastruktur.js" /* webpackChunkName: "component---src-pages-infrastruktur-js" */),
  "component---src-pages-insentif-js": () => import("./../../../src/pages/insentif.js" /* webpackChunkName: "component---src-pages-insentif-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-kawasan-industri-js": () => import("./../../../src/pages/kawasan-industri.js" /* webpackChunkName: "component---src-pages-kawasan-industri-js" */),
  "component---src-pages-kawasan-industri-kek-js": () => import("./../../../src/pages/kawasan-industri-kek.js" /* webpackChunkName: "component---src-pages-kawasan-industri-kek-js" */),
  "component---src-pages-peluang-investasi-compare-js": () => import("./../../../src/pages/peluang-investasi/compare.js" /* webpackChunkName: "component---src-pages-peluang-investasi-compare-js" */),
  "component---src-pages-peluang-investasi-confirm-investor-js": () => import("./../../../src/pages/peluang-investasi/confirm-investor.js" /* webpackChunkName: "component---src-pages-peluang-investasi-confirm-investor-js" */),
  "component---src-pages-peluang-investasi-daerah-js": () => import("./../../../src/pages/peluang-investasi/daerah.js" /* webpackChunkName: "component---src-pages-peluang-investasi-daerah-js" */),
  "component---src-pages-peluang-investasi-detail-js": () => import("./../../../src/pages/peluang-investasi/detail.js" /* webpackChunkName: "component---src-pages-peluang-investasi-detail-js" */),
  "component---src-pages-peluang-investasi-e-catalog-js": () => import("./../../../src/pages/peluang-investasi/e-catalog.js" /* webpackChunkName: "component---src-pages-peluang-investasi-e-catalog-js" */),
  "component---src-pages-peluang-investasi-index-js": () => import("./../../../src/pages/peluang-investasi/index.js" /* webpackChunkName: "component---src-pages-peluang-investasi-index-js" */),
  "component---src-pages-peluang-investasi-upcoming-projects-js": () => import("./../../../src/pages/peluang-investasi/upcoming-projects.js" /* webpackChunkName: "component---src-pages-peluang-investasi-upcoming-projects-js" */),
  "component---src-pages-peta-realisasi-investasi-js": () => import("./../../../src/pages/peta-realisasi-investasi.js" /* webpackChunkName: "component---src-pages-peta-realisasi-investasi-js" */),
  "component---src-pages-sektor-js": () => import("./../../../src/pages/sektor.js" /* webpackChunkName: "component---src-pages-sektor-js" */)
}

